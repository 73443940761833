$primary: #1fc36a;
$primary1: #1fc36a;
$primary2: #216869;
$primary3: #5c4ae4;
$gray1: #444444;
$gray2: #666666;
$gray3: #888888;
$gray4: #cccccc;
$gray5: #eeeeee;
$gray6: #efefef;

$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;


.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.force-full-width {
  width: 100vw;
	margin-left: calc(-50vw + 50%);
	margin-right: calc(50vw - 50%);
}
.sms-modal {
	p {
		font-size: 0.85em;
	}
	.phone-input {
		padding-bottom: 10px;

		input {
			text-align: center;
			border: 0;
			border-bottom: 1px solid #ccc;
			width: 18px;
			margin: 0;
			font-size: 1.5em;
			color: $gray1;
			padding: 0;
		}
		input[type=number] {
			-moz-appearance: textfield;
		}
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		.split {
			color: $gray3;
			line-height: 0.5em;
			position: relative;
		}
		&.code {
			font-size: 38px;
			input {
				width: 40px;
			}
		}
	}
	.verify-type {
		min-width: 300px;
	}
}
